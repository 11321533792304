#payment-success {
  background: #fafafa;
}

#payment-success .card {
  background: white;
  /* padding: 60px; */
  padding: 60px 20px 60px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}

#payment-success .card2 {
  background: white;
  padding: 60px 20px 60px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}

#payment-success .header {
  top: 0;
  background: white;
  margin-bottom: 16px;
}

#payment-success .title {
  color: var(--neutral-900, #18181b);

  /* Title 2/M */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-align: center;
  margin-top: 8px;
}

#payment-success .money {
  color: var(--neutral-900, #18181b);

  /* Heading 2/B */
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  margin-top: 8px;
}
#payment-success .title-detail {
  color: var(--neutral-900, #18181b);

  /* Body/M */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

#payment-success .counter-text {
  color: var(--neutral-900, #18181b);

  /* Body/R */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

#payment-success .back-home {
  color: var(--primary-basic, #2d9764);

  /* Body/M */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin-top: 20px;
}

#payment-success .info {
  color: var(--neutral-900, #18181b);
  text-align: center;

  /* Body/R */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

td {
  color: var(--neutral-900, #18181b);

  /* Body/R */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  height: 50px;
}

table {
  border-collapse: collapse;
}

tr {
  border-bottom: 0.5px solid #e4e4e7;
}

#payment-success .btn {
  border: 0.5px solid var(--primary-basic, #2d9764);
  background-color: white;
  color: black;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  color: var(--primary-basic, #2d9764);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  gap: 8px;
  align-self: stretch;
}

#payment-success .btn-no-border {
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: inline-block;
  color: var(--primary-basic, #2d9764);
  /* Body/M */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin-top: 20px;
}

#payment-success .text-failed {
  color: var(--error-basic, #fa5050);
  text-align: center;

  /* Body/R */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding-top: 8px;
}
